import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import styled from "styled-components";

export const tagColors = {
  arrays: "#4299e1",
  binarysearchtrees: "#ff6b6b",
  recursion: "#9c88ff",
  trees: "#2ecc71",
  ctci: "#78e08f",
  leetcode: "#fed330",
  dynamicprogramming: "#e84393",
  hashtables: "#8395a7",
  graphs: "#ffb142",
  strings: "#ee82ee",
  linked_lists: "#34495e",
  stacksqueues: "#2d3436",
  sortingsearching: "#00b894",
  object_oriented_design: "#00cec9"
};

export const theme = createMuiTheme({
  overrides: {
    /*MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#e6ffe6",
          "&:hover": {
            backgroundColor: "#ffe6e6"
          }
        }
      },
      button: {
        "&:hover": {
          backgroundColor: "#e6ffe6"
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "#2ed573"
        }
      }
    }*/
  }
});

export const useStyles = makeStyles({
  item: {
    //color: theme.palette.secondary.main,
    "& span, & svg": {
      fontWeight: "bold",
      fontSize: "15px"
    }
  }
});
