import React from "react";
import { Link } from "gatsby";
import { Flex, Button, Heading, Text, Divider } from "theme-ui";
import { FaRegGrinHearts } from "react-icons/fa";

const styles = {
  wrapper: {
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    p: 4
  },
  heading: {
    color: `omegaDark`,
    svg: {
      color: `#f39c12`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2
    },
    "li:before": {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`
    }
  },
  button: {
    display: `block`,
    margin: 10
  }
};

const BannerVertical = ({ userName }) => (
  <Flex sx={styles.wrapper}>
    <Heading variant="h2" sx={styles.heading}>
      <FaRegGrinHearts />
      {`Welcome${userName ? ` ${userName}` : ""}!`}
    </Heading>
    <Heading variant="h4" sx={styles.subheading}>
      Hooray! Our full syllabus is now unlocked.
    </Heading>
    <Text as="ul" variant="small" sx={styles.list}>
      <li>
        You now have access to the platform for all your algorithm and data
        structure needs.
      </li>
      <li>
        We can't wait to meet you! To meet your instructors{" "}
        <Link
          to="https://github.com/devclassio"
          style={{ color: "gray" }}
          target="_blank"
        >
          click here
        </Link>
      </li>
      <li>
        To view your progress and manage your account{" "}
        <Link to="/profile" style={{ color: "gray" }} target="_blank">
          click here
        </Link>
      </li>
      <li>
        You can download code solutions on our{" "}
        <Link
          to="https://github.com/devclassio"
          style={{ color: "gray" }}
          target="_blank"
        >
          Github
        </Link>{" "}
        page.
      </li>
    </Text>
    <Button
      variant="primary"
      as={Link}
      to="/product"
      sx={styles.button}
      aria-label="Learn More"
    >
      Learn More
    </Button>
  </Flex>
);

export default BannerVertical;
