import React, { useEffect } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { theme, useStyles, tagColors } from "./styling";
import { ThemeProvider } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import "./tag.css";
import { Badge, NavLink } from "theme-ui";
import { navigate, Link } from "gatsby";
import { FaStar, FaLock, FaLockOpen } from "react-icons/fa";
import { GiSightDisabled } from "react-icons/gi";
import LoginDialog from "@widgets/LoginDialog";
import {
  createSeedUsers,
  deleteSeedUsers,
  updateUserQuestionStatusComplete,
  updateUserQuestionStatusIncomplete,
  getUserQuestionStatuses
} from "@elegantstack/core-blog/src/firebase";

const FreeBadge = () => (
  <Badge variant="featured" style={{ color: "#bdc3c7" }}>
    <FaLockOpen />
  </Badge>
);

const LockedBadge = () => (
  <Badge variant="featured" style={{ color: "#fab1a0" }}>
    <FaLock />
  </Badge>
);

const PremiumBade = () => (
  <Badge variant="featured" style={{ color: "#fdcb6e" }}>
    <FaStar />
  </Badge>
);

const PrivateBadge = () => (
  <Badge variant="featured" style={{ color: "#bdc3c7" }}>
    <GiSightDisabled />
  </Badge>
);

const SectionTable = ({ index, posts, user, isDark }) => {
  const classes = useStyles();
  // Sorted posts by public -> private.
  const sortedPosts = posts
    .sort((a, b) => {
      if (a.private) return 1;
      else if (b.private) return -1;
      else return a - b;
    })
    .sort((a, b) => {
      if (!a.featured) return 1;
      else if (!b.featured) return -1;
      else return a - b;
    });

  const userId = user ? user.uid : null;
  const userCompleted = user?.complete ? user.complete : [];
  const userUpdateStatus = user?.complete ? 1 : 0;
  //console.log(userCompleted);

  const postsMeta = sortedPosts.map((post, i) => ({
    title: post.title,
    isPrivate: post.private,
    isPremimum: post.premium,
    isFeatured: post.featured,
    author: post.author.name,
    categoryName: post.categoryName,
    id: i,
    checked: userCompleted.includes(post.slug),
    tags: post.tags.map(tag => tag.name),
    //readTime: calculateTimeToRead(post.timeToRead, post.body),
    url: post.private ? null : post.slug,
    index: `${index}.${i + 1}`
  }));

  // Login dialog
  const [open, setOpen] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = value => {
    setOpen(false);
  };

  // Item check
  const [checked, setChecked] = React.useState(postsMeta.map(post => -1));

  // Update checked status when all user data exists.
  useEffect(() => {
    setChecked(
      postsMeta.map(post => (userCompleted.includes(post.url) ? post.url : -1))
    );
  }, [userUpdateStatus]);

  const handleToggle = postId => () => {
    const currentIndex = checked.indexOf(postId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(postId);
      updateUserQuestionStatusComplete(userId, postId);
    } else {
      newChecked.splice(currentIndex, 1);
      updateUserQuestionStatusIncomplete(userId, postId);
    }
    setChecked(newChecked);
    //setChecked(data[post.id].isChecked = !data[post.id].isChecked);
  };

  const handlePlay = url => {
    user ? navigate(url) : openDialog();
  };

  return (
    <div style={{ width: "100%", color: "#333333" }}>
      <List>
        {postsMeta.map((post, i) => {
          const labelId = `checkbox-list-label-${post.url}`;
          const isUserPermitted =
            (post.isFeatured || user != null) && !post.isPrivate; // && !post.isPremium;

          return (
            <ThemeProvider theme={theme}>
              <ListItem
                selected={checked.indexOf(post.url) !== -1 && isUserPermitted}
                divider
                disabled={!isUserPermitted}
                key={labelId + i}
                dense
                button
                onClick={handleToggle(post.url)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    checked={
                      checked.indexOf(post.url) !== -1 && isUserPermitted
                    }
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    disabled={user == null}
                    style={{ color: "#27ae60" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.item}
                  id={labelId}
                  primary={
                    <NavLink
                      style={{
                        textDecoration: null,
                        color: isDark ? "white" : "black"
                      }}
                      // Changed below to false for improved UX. Clicking on mobile avoids checking box
                      href={isUserPermitted ? post.url : null}
                      target="_blank"
                    >
                      {post.isFeatured || isUserPermitted ? null : (
                        <LockedBadge />
                      )}
                      {post.isPremimum && <PremiumBade />}
                      {post.isPrivate && <PrivateBadge />}
                      {post.title}
                    </NavLink>
                  }
                  secondary={
                    <div>
                      {post.tags.map((tag, index) => (
                        /*<button
                          className="tag"
                          style={{
                            backgroundColor:
                              tagColors[tag.replace("#", "").replace("-", "_")],
                          }}
                        >
                          {tag}
                        </button>*/
                        <Badge
                          variant="tag"
                          key={index}
                          as={Link}
                          style={{
                            backgroundColor:
                              tagColors[
                                tag.replace("#", "").replace(/-/g, "_")
                              ],
                            margin: 2,
                            color: "white"
                          }}
                        >
                          {tag}
                        </Badge>
                      ))}
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    style={{ color: isDark ? "white" : "black" }}
                    onClick={() => {
                      handlePlay(post.url);
                    }}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <LoginDialog open={open} onClose={closeDialog} />
            </ThemeProvider>
          );
        })}
      </List>
    </div>
  );
};

export default SectionTable;

SectionTable.defaultProps = {
  title: "Topic Title"
};

SectionTable.propTypes = {
  title: PropTypes.string
};
