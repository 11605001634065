import React, { useContext } from "react";
import { Stack, Main, Sidebar } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import CategorySection from "@components/CategorySection";
import Seo from "@widgets/Seo";
import NewsletterExpanded from "@widgets/NewsletterExpanded";
import LoginCard from "@widgets/LoginCard";
import BannerVertical from "@widgets/BannerVertical";
import { Text } from "theme-ui";
import { AuthContext } from "@elegantstack/core-blog/src/context/auth";
import { usePostsByCatagories } from "@helpers-blog";

export default ({ pageContext, ...props }) => {
  const { pageContext: { services = {} } = {} } = props;
  const catagories = usePostsByCatagories();
  const postsByCategory = catagories.group;
  // const postsByCategory = POSTS;
  const { user } = useContext(AuthContext) || {};
  const totalPosts = catagories.totalCount;
  const providerUserData =
    user && user?.providerData ? user.providerData[0] : null;
  const [firstName, ...lastName] = providerUserData
    ? providerUserData.displayName.split(" ")
    : "Hacker";

  return (
    <>
      <Seo title="Content" />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header={`${totalPosts} Questions 🚀 😤`}
            subheader="Streamline preparation with our recommended learning path"
          />
          <Text variant="p">
            All of our questions are hand picked. We focus on working through
            problems and algorithms which help develop intuition and a deep
            understanding of problems. With a focus on approach and pattern
            recognition you'll be well prepared to solve any question thrown
            your way!
          </Text>
          {postsByCategory.map((category, index) => (
            <div style={{ padding: 5 }}>
              <CategorySection
                index={index + 1}
                key={category.id}
                title={category.categoryName}
                posts={category.nodes}
                totalPosts={category.totalCount}
                user={user}
              />
            </div>
          ))}
          <Divider />
        </Main>
        <Divider />
        <Sidebar>
          {user ? (
            <BannerVertical userName={firstName}></BannerVertical>
          ) : (
            <LoginCard />
          )}
        </Sidebar>
      </Stack>
    </>
  );
};
