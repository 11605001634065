import React from "react";
import PropTypes from "prop-types";
import { Text } from "theme-ui";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import SectionTable from "@components/SectionTable";
import { useColorMode } from "theme-ui";

const useStyles = makeStyles(theme => ({
  heading: {
    //flexBasis: '33.33%',
    //flexShrink: 0,
    paddingRight: "1%",
    fontWeight: "700",
    fontSize: 20
  },
  sectionFontColor: {
    color: "white"
  },
  accordionExpandColor: {
    backgroundColor: "white"
  }
}));

const CategorySection = ({ index, title, posts, totalPosts, user }) => {
  const classes = useStyles();
  const categoryColor = totalPosts ? posts[0].category.color : "";
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  return (
    <Accordion
      style={{ backgroundColor: categoryColor }}
      className={classes.sectionFontColor}
      defaultExpanded={index == 1}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.sectionFontColor} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ fill: "white" }}
      >
        <Text className={classes.heading}>{title}</Text>
        <Text className={classes.heading}>({totalPosts})</Text>
      </AccordionSummary>
      <AccordionDetails
        style={{ backgroundColor: isDark ? "#2d3748 " : "white" }}
      >
        <SectionTable
          index={index}
          isDark={isDark}
          title={title}
          posts={posts}
          totalPosts={totalPosts}
          user={user}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CategorySection;

CategorySection.defaultProps = {
  title: "Topic Title"
};

CategorySection.propTypes = {
  title: PropTypes.string
};
